import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import { Radio, RadioProps } from '@mui/material'
import { ReactElement } from 'react'

import './CardRadio.scss'

interface CardProps {
  imageSrc: string
  header: string
  description: string
  tag?: string
}

interface CardSelectorProps extends RadioProps, CardProps {}

/**
 * Component used as a replacement for icon in CardRadio comp
 * @param props
 * @returns
 */
function CardRadioCard(props: CardProps): ReactElement {
  const { imageSrc, header, description, tag } = props

  return (
    <div className="card-radio-card">
      <img src={imageSrc} height="90px" width="90px" alt="" />
      <div className="card-radio-card__right-container">
        <div className="card-radio-card__right-container__text-container">
          {!!tag && (
            <div className="card-radio-card__right-container__text-container__tag">
              {tag}
            </div>
          )}
          <div className="pel-typography-b3-bold">{header}</div>
          <div>{description}</div>
        </div>
        <CheckCircleRoundedIcon className="card-radio-card__right-container__checked-icon" />
      </div>
    </div>
  )
}

/**
 * Custom implementation of Radio comp (card with image/text)
 * @param props
 * @returns
 */
export default function CardRadio(props: CardSelectorProps): ReactElement {
  const { imageSrc, header, description, tag, ...radioProps } = props
  const cardEl = (
    <CardRadioCard
      imageSrc={imageSrc}
      header={header}
      description={description}
      tag={tag}
    />
  )

  return (
    <Radio
      className="card-radio"
      disableRipple
      disableTouchRipple
      checkedIcon={cardEl}
      icon={cardEl}
      inputProps={{ 'aria-label': header }}
      {...radioProps}
    />
  )
}
