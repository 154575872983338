import {
  AutocompleteRenderInputParams,
  Box,
  BoxProps,
  FilledInput,
  FilledInputProps,
  FormControl,
  InputBaseComponentProps,
  InputLabel,
  Select,
  SelectProps,
} from '@mui/material'
import React, { ReactElement, ReactNode, useEffect, useState } from 'react'

import FormHelperText from '~components/FormHelperText'

import './TextField.scss'

export type TextFieldProps = {
  label?: React.ReactNode
  name?: string
  value?: string
  disabled?: boolean
  error?: boolean
  helperText?: string | JSX.Element
  autoComplete?: string
  select?: boolean
  type?: string
  endAdornment?: ReactNode
  inputComponent?: FilledInputProps['inputComponent']
  inputProps?: InputBaseComponentProps
  InputProps?: AutocompleteRenderInputParams['InputProps']
  children?: ReactNode
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  onChange?: SelectProps<string>['onChange'] &
    React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
} & Omit<BoxProps, 'onChange'>

/**
 * TextField
 */
export default function TextField(props: TextFieldProps): ReactElement {
  const {
    label,
    name,
    value,
    disabled,
    error,
    helperText,
    autoComplete,
    select,
    children,
    type,
    endAdornment,
    inputComponent,
    inputProps,
    InputProps,
    onBlur,
    onChange,
    ...boxProps
  } = props

  const labelId = name + '-label'
  const errorId = name + '-error-text'
  const ariaDescriptionProps = {
    'aria-labelledby': labelId,
    'aria-describedby': errorId,
  }

  const getErrorProps = (isError: boolean | undefined) =>
    isError ? { 'aria-errormessage': errorId, 'aria-invalid': true } : {}
  const [errorProps, setErrorProps] = useState(getErrorProps(error))
  useEffect(() => {
    setErrorProps(getErrorProps(error))
  }, [error])

  return (
    <Box
      {...boxProps}
      className={`pel-PelagoTextField ${label ? '' : 'no-label'}`}
    >
      <FormControl variant="filled" fullWidth={true} error={error}>
        {label ? (
          <InputLabel htmlFor={name} disabled={disabled} id={labelId}>
            {label}
          </InputLabel>
        ) : (
          <></>
        )}
        {select ? (
          <Select
            id={name}
            name={name}
            value={value}
            disabled={disabled}
            disableUnderline={true}
            labelId={labelId}
            onBlur={onBlur}
            onChange={onChange}
            MenuProps={{
              marginThreshold: 0,
            }}
            inputProps={{ ...ariaDescriptionProps, ...errorProps }}
          >
            {children}
          </Select>
        ) : (
          <FilledInput
            name={name}
            value={value}
            autoComplete={autoComplete}
            disableUnderline={true}
            type={type ?? 'text'}
            endAdornment={endAdornment}
            disabled={disabled}
            onBlur={onBlur}
            onChange={onChange}
            inputComponent={inputComponent}
            inputProps={{
              ...ariaDescriptionProps,
              ...errorProps,
              ...inputProps,
              id: name,
            }}
            {...InputProps}
          />
        )}
        <FormHelperText id={errorId} disabled={disabled} error={error}>
          {helperText}
        </FormHelperText>
      </FormControl>
    </Box>
  )
}
