import { builder } from '@builder.io/sdk'

import {
  trackLPConversionError,
  trackLPConversionIdentified,
  trackLPConversionSuccess,
} from '~services/analyticsService/analytics.service'

export class BuilderService {
  /**
   *  As the reg flow will use its own Builder.io space, tracking conversions
   *  will be done outside of the Buidler SDK so that it can track to the LP
   *  space using the LP API key.
   */
  static checkBuilderParamsAndTrackLPConversions() {
    const builderOverrideSessionIdParamKey = 'builder.overrideSessionId'
    const builderOverrideVisitorIdParamKey = 'builder.overrideVisitorId'
    const urlParams = new URLSearchParams(window.location.search)
    const hasBuilderOverrideSessionIdParam = urlParams.has(
      builderOverrideSessionIdParamKey
    )
    const builderOverrideSessionIdParam = urlParams.get(
      builderOverrideSessionIdParamKey
    )
    const builderOverrideVisitorIdParam = urlParams.get(
      builderOverrideVisitorIdParamKey
    )

    const LandingPageBuilderAPIKey = import.meta.env.VITE_BUILDER_LP_API_KEY

    if (hasBuilderOverrideSessionIdParam && builderOverrideSessionIdParam) {
      const originalHref = window.location.href

      // Remove the builder override params from the URL to avoid tracking the
      // same conversion multiple times, especially if shared.
      const newUrlParams = new URLSearchParams(urlParams)
      newUrlParams.delete(builderOverrideSessionIdParamKey)
      newUrlParams.delete(builderOverrideVisitorIdParamKey)
      window.history.replaceState(
        {},
        '',
        `${window.location.pathname}?${newUrlParams.toString()}`
      )

      trackLPConversionIdentified({
        LPBuilderSessionId: builderOverrideSessionIdParam,
        LPBuilderVisitorId: builderOverrideVisitorIdParam,
      })
      fetch('https://cdn.builder.io/api/v1/track', {
        body: JSON.stringify({
          events: [
            {
              type: 'conversion',
              data: {
                metadata: {
                  url: originalHref,
                  ownerId: LandingPageBuilderAPIKey,
                  sessionId: builderOverrideSessionIdParam,
                },
                ownerId: LandingPageBuilderAPIKey,
                userAttributes: builder.getUserAttributes(),
                sessionId: builderOverrideSessionIdParam,
                ...(builderOverrideVisitorIdParam && {
                  visitorId: builderOverrideVisitorIdParam,
                }),
              },
            },
          ],
        }),
        method: 'POST',
      })
        .then(() => {
          trackLPConversionSuccess()
        })
        .catch((error: Error | string) => {
          trackLPConversionError(error)
        })
    }
  }
}
